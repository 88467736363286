import React, { useState, useEffect } from 'react';
import cascading from '../assests/css/about.module.css';
import Footer from '../common/footer'
import Header from '../common/header';

import img1 from "../assests/images/get_started/img1.png";
import img2 from "../assests/images/get_started/img2.png";
import img3 from "../assests/images/get_started/img3.png";
import img4 from "../assests/images/get_started/img4.png";
import img5 from "../assests/images/get_started/img5.png";
import img6 from "../assests/images/get_started/img6.png";
import img7 from "../assests/images/get_started/img7.png";


import img11 from "../assests/images/bitdealz/img1.png";
import img12 from "../assests/images/bitdealz/img2.png";
import img13 from "../assests/images/bitdealz/img3.png";
import img14 from "../assests/images/bitdealz/img4.png";
import img15 from "../assests/images/bitdealz/img5.png";

import img21 from "../assests/images/farming/img1.png";
import img22 from "../assests/images/farming/img2.png";




import AOS from "aos";
import "aos/dist/aos.css";

export default function Whitepaper() {

    const [whitepaper, setWhitepaper] = useState('step1')

    useEffect(() => {
        AOS.init({
        });
    }, []);

    const handleTab = (val) => {
        setWhitepaper(val)
    }

    return (
        <>
            <Header />
           



            <div className={`px-0 px-sm-3 px-lg-4 pb-5 ${cascading.containmax} ${cascading.customContainer} pt-5`}>

            <div className={`${cascading.tabCustomSec}`}>
                        <div className={`${cascading.leftSec}`}>
                            <div className={`${cascading.contentSec}`}>

                                <button type="button" className={whitepaper === "step1"?`${cascading.sideHeaderActive}`:`${cascading.sideHeader}`} onClick={() => handleTab("step1")}>Abstract</button>


                                <button type="button" className={whitepaper === "step2"?`${cascading.sideHeaderActive}`:`${cascading.sideHeader}`} onClick={() => handleTab("step2")}>How to Get Started with BitDealz Exchange?</button>


                                <button type="button" className={whitepaper === "step3"?`${cascading.sideHeaderActive}`:`${cascading.sideHeader}`} onClick={() => handleTab("step3")}>BitDealz Exchange</button>


                                <button type="button" className={whitepaper === "step4"?`${cascading.sideHeaderActive}`:`${cascading.sideHeader}`} onClick={() => handleTab("step4")}>Farming</button>

                            </div>
                        </div>
                        <div className={`${cascading.rightSec}`}>
                            <div className={`${cascading.contentSec}`}>
                                {
                                    whitepaper === 'step1' ?
                                        <>
                                            <div className={`${cascading.abstractContent}`}>
                                                <h1 className={`${cascading.header} my-0`}>Abstract</h1>
                                                <p className={`${cascading.content} mb-0`}>
                                                    The evolution of crypto trading started with the emergence of decentralized Financial systems known as DeFi. Decentralized Finance (DeFi) empowers users to gain control over their digital holdings and transactions while establishing anonymity. BitDealz Exchange is the latest and ground-breaking entry to the world of DeFi with a motive to empower people. BitDealz Exchange is the new-age protocol allowing users to swap crypto tokens without an account in a decentralized environment. It is a multi-chain protocol (currently, runs on BSC) that supports various networks & its associated tokens that opens up an array of benefits like low gas fees, reliable DeFi apps, decentralization, etc., for token holders. BitDealz Exchange gas fees are very low compared to other DEX, which is an additional bonus for the platform users. BitDealz Exchange uses a more advanced Automated Market Maker (AMM) model to match buyer and seller orders or automate swaps through liquidity pools. BitDealz Exchange enables users to trade from the wallet application directly and allows people to take ownership of their assets. Apart from numerous benefits, the token holders can earn high native tokens for free with high-interest gains through staking. Further, high APY and APR are the additional rewards that come with staking tokens.
                                                </p>
                                            </div>
                                        </> : whitepaper === 'step2' ?
                                            <>
                                                <div className={`${cascading.getstartedContent}`}>
                                                    <div className={`${cascading.bitdealzExchange} mb-5`}>
                                                        <h1 className={`${cascading.header} mt-0`}>BitDealz Exchange</h1>
                                                        <div className={`${cascading.group}`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                A Ground-breaking DEX Protocol
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                Seamlessly earn, win, or trade cryptocurrency through the innovative project with the BitDealz Exchange protocol.
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                BitDealz Exchange is a futuristic protocol that aims to support numerous networks soon (currently, launched successfully on BSC network with native-token support) to empower platform users and enable them to seamlessly and conveniently make crypto transactions with new-age features compared to other platforms. The ultimate goal of the BitDealz Exchange is to make cryptocurrency mainstream and support crypto-focused communities by helping them to access reliable crypto-based services.
                                                            </p>
                                                        </div>

                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                New and Most Reliable
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                BitDealz Exchange is the latest DEX, and compared to other exchanges, it is a more beginner-friendly and rewarding platform.
                                                            </p>

                                                        </div>

                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                Why Choose BitDealz Exchange?
                                                            </p>
                                                            <p className={`${cascading.content} mb-3` }>
                                                                The main advantage of running on multiple networks like Binance Smart Chain (BSC) is multi-chain compatibility, multi-wallet support, improved transaction speed & rate, etc. In addition, BitDealz Exchange users pay very low transaction costs compared to other top DEXs.
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                In addition, BitDealz Exchange continuously releases exciting projects that help traders to make the most of their digital assets.
                                                            </p>
                                                        </div>

                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                Decentralized and Transparent
                                                            </p>
                                                            <p className={`${cascading.content} mb-3` }>
                                                                Decentralization enables crypto traders to take ownership of crypto holdings directly when they trade on the BitDealz Exchange.
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                In addition, BitDealz Exchange is completely transparent and free for most features (except for a few, as stated in the document).
                                                            </p>
                                                        </div>


                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                Earn, Trade, and Multiply
                                                            </p>
                                                            <p className={`${cascading.content} mb-3` }>
                                                                BitDealz Exchange helps crypto traders earn, trade, and multiply their assets in the following ways.
                                                            </p>
                                                            <p className={`${cascading.content} mb-3` }>
                                                                Earn
                                                            </p>
                                                            <p className={`${cascading.content} mb-3` }>
                                                                Seamlessly earn BCoin and LP tokens (Liquidity tokens) for free through various ways and open unlimited earning opportunities.
                                                            </p>


                                                            <p className={`${cascading.content} mb-4`}>
                                                                Stake BCoin in the Liquidity Pool and multiply your assets. New projects will enable users to earn more based on their staking.
                                                            </p>
                                                        </div>


                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                Trade
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                In addition to cryptocurrency trading, it is easy to trade LP tokens for BCoin. Simply stake LP tokens to get equivalent BCoin with a high APR.
                                                            </p>

                                                        </div>


                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                Multiply
                                                            </p>
                                                            <p className={`${cascading.content} mb-3` }>
                                                                Now, you can multiply your earning by staking tokens in the liquidity pool even if the trading pair isn’t supported on the Farm page.
                                                            </p>
                                                            <p className={`${cascading.content} mb-3` }>
                                                                Industry-best Security Practices
                                                            </p>
                                                            <ul className={`${cascading.listContent} ms-3`}>
                                                                <li>Industry-best Security Practices</li>
                                                                <li>Open-source infrastructure</li>
                                                                <li>Publicly visible Smart contracts</li>
                                                                <li>BSC Scan verified </li>
                                                                <li>Maximum Transparency</li>
                                                                <li>Multi-sig for contracts</li>
                                                            </ul>
                                                        </div>


                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                Audited Smart Contract
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                Check out the audited smart contract once it is available!
                                                            </p>

                                                        </div>
                                                    </div>


                                                    <div className={`${cascading.getStarted}`}>
                                                        <h1 className={`${cascading.header} mt-0`}>How to Get Started with BitDealz Exchange?</h1>

                                                        <p className={`${cascading.content} mb-4`}>
                                                            Get started with the all-new BitDealz Exchange and trade crypto effortlessly with the help of the following guidelines.
                                                        </p>
                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                Begin Your New-age Trading Journey
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                Leap into the world of cryptocurrency trading in just three steps.
                                                            </p>
                                                        </div>
                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                1. Create a Crypto Wallet
                                                            </p>

                                                            <div>
                                                                <img src={img1} alt='img1' className={`${cascading.img1} ${cascading.cmnImg} img-fluid`} />
                                                                <p className={`${cascading.imgFtr} mb-0 mt-2 text-center`}>Create a Crypto Wallet</p>
                                                            </div>
                                                            <div className='mt-3 mt-sm-4 mt-lg-4 mt-xl-5'>
                                                                <img src={img2} alt='img2' className={`${cascading.img2} ${cascading.cmnImg} img-fluid`} />
                                                                <p className={`${cascading.imgFtr} mb-0 mt-2 text-center`}>Images of Popular wallets</p>
                                                            </div>
                                                            <p className={`${cascading.content} mb-0 mt-3 mt-sm-4 mt-lg-4 mt-xl-5`}>The first requirement is to create a wallet (available in desktop and mobile versions) to store crypto holdings and to start using the BitDealz Exchange.</p>

                                                            <p className={`${cascading.contentHeader} mt-4`}>
                                                                Tips to Download a Wallet
                                                            </p>
                                                            <ol className={`${cascading.downloadTips}`}>
                                                                <li>Install the latest version from the official site</li>
                                                                <li>Follow the guidelines to install</li>
                                                                <li>Back up the recovery procedures</li>
                                                            </ol>
                                                            <p className={`${cascading.contentHeader} mt-4`}>
                                                                How To Choose Between Mobile and Desktop Wallet?
                                                            </p>
                                                            <p className={`${cascading.content} mb-0 mt-3`}>
                                                                Select the preferred wallet based on its advantages and what suits you the most.
                                                            </p>
                                                            <div  className={`${cascading.ctntextbold} mt-4`}>
                                                                <span className={`${cascading.txtHighlights}`}>Mobile wallet</span> - Portable, easy to use, requires an internet connection. Popular mobile wallets are Metamask, Coinbase, Trust Wallet, etc. Mobile wallets are available as applications across iOS and Android platforms.
                                                            </div>
                                                            <div className={`${cascading.ctntextbold} mt-4`}>
                                                                <span className={`${cascading.txtHighlights}`}>Desktop wallet</span> - Highly secure, access friendly, etc. Some Popular mobile wallets are Metamask, Binance, and Trust Wallet. Desktop wallets are available as both standalone applications and plugins for browsers.
                                                            </div>
                                                            <div className={`${cascading.group} mt-4`}>
                                                                <p className={`${cascading.contentHeader}`}>
                                                                    Important:
                                                                </p>
                                                                <p className={`${cascading.content} mb-4`}>
                                                                    It is important to securely save your private key and seed phrase (recovery phrase) and not reveal it to others. BitDealz Exchange representatives will never ask for your private key or seed phrase.
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                2. Get Supported Tokens
                                                            </p>

                                                            <div>
                                                                <img src={img3} alt='img1' className={`${cascading.img3} ${cascading.cmnImg} img-fluid`} />
                                                                <p className={`${cascading.imgFtr} mb-0 mt-2 text-center`}>Create a Crypto Wallet</p>
                                                            </div>
                                                            <p className={`${cascading.content} mb-0 mt-3 mt-sm-4 mt-lg-4 mt-xl-5`}>
                                                                For instance, BEP20 - A native Tokens of Binance Smart Chain (BSC)
                                                            </p>


                                                            <ul className={`${cascading.listContent} mt-3 ms-3`}>
                                                                <li>BitDealz Exchange is a  multi-chain supported platform (runs on BSC now) and you need a BEP20 or other tokens to pay gas fees and carry out all the activities </li>
                                                                <li>The platforms supports and allows token holders to trade, stake, etc., using any appropriate tokens(BEP20)</li>
                                                                <li>It is easy to convert the tokens to native tokens (like BEP20) in the suitable platforms</li>
                                                            </ul>
                                                        </div>
                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                3. Integrate Wallet to BitDealz Exchange
                                                            </p>

                                                            <div>
                                                                <img src={img4} alt='img1' className={`${cascading.img3} ${cascading.cmnImg} img-fluid`} />
                                                                <p className={`${cascading.imgFtr} mb-0 mt-2 text-center`}>Integrate Wallet to BitDealz Exchange</p>
                                                            </div>
                                                            <p className={`${cascading.content} mb-0 mt-3 mt-sm-4 mt-lg-4 mt-xl-5`}>
                                                                Once created a wallet and secured the necessary BEP20 tokens, connect the wallet to the BitDealz Exchange to start trading.
                                                            </p>

                                                        </div>


                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                1.2 Help Section
                                                            </p>
                                                            <p className={`${cascading.content} mb-0 mt-3`}>
                                                                This section helps you to fix the pending transaction in a few easy steps
                                                            </p>
                                                            <p className={`${cascading.content} mb-0 mt-3`}>
                                                                If the transaction is pending on wallet (Metamask),
                                                            </p>

                                                            <ul className={`${cascading.listContent} mt-3 ms-3`}>
                                                                <li>Open Metamask Plugin by clicking the highlighted icon on the screen</li>

                                                                <div className='mt-4'>
                                                                    <img src={img5} alt='img1' className={`${cascading.img5} ${cascading.cmnImg} img-fluid`} />
                                                                    <p className={`${cascading.imgFtr} mb-0 mt-2 text-center`}>Wallet Icon</p>
                                                                </div>
                                                                <div className='mt-3 mt-sm-4 mt-lg-4 mt-xl-5 mb-4'>
                                                                    <img src={img6} alt='img1' className={`${cascading.img6} ${cascading.cmnImg} img-fluid`} />
                                                                    <p className={`${cascading.imgFtr} mb-0 mt-2 text-center`}>Image Showing Metamask Plugin</p>
                                                                </div>
                                                                <li>Click the <span className={`${cascading.txtHighlights}`}>coloured</span> icon and choose Settings from the menu</li>
                                                                <div className='mt-3 mt-sm-4 mt-lg-4 mt-xl-5 mb-4'>
                                                                    <img src={img7} alt='img1' className={`${cascading.img7} ${cascading.cmnImg} img-fluid`} />
                                                                    <p className={`${cascading.imgFtr} mb-0 mt-2 text-center`}>Image Showing Settings Option</p>
                                                                </div>
                                                                <li><span className={`${cascading.txtHighlights}`}>In the next tab, click Advanced tab</span></li>
                                                                <li>Then, locate Advanced gas controls and toggle it to On</li>
                                                                <li>Now in the same section, locate <span className={`${cascading.txtHighlights}`}>Customize Transaction Nonce</span> (note it down) and toggle it to On</li>
                                                                <li>Now, go to Metamask and click Assets to find the token type of transaction (For instance, BCoin)</li>
                                                                <li>In the Token menu, locate Pending Transaction in the Queue area and select the transaction for more details</li>
                                                                <li>Search for <span className={`${cascading.txtHighlights}`}>Nonce</span> and mark it down</li>
                                                                <li>Now, create a new transaction with higher transaction fees to make it successful</li>
                                                                <li>On the next Confirmation page, make sure the transaction or gas fee is higher than previous </li>
                                                                <li>Now, find Customize Nonce Tab and enter the number that you’ve marked in step 8 and click Confirm</li>
                                                                <li>Now, Open <span className={`${cascading.txtHighlights}`}>Metamask</span> and click Activity to check where you can find the new transaction on top</li>
                                                            </ul>
                                                        </div>
                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                1.3 FAQ
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                In the FAQ section, answers to some common questions raised by the BitDealz Exchange community are given.
                                                            </p>

                                                            <p className={`${cascading.contentHeader}`}>
                                                                How Safe is BitDealz Exchange?
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                BitDealz Exchange is built on Open-source software, and in addition, the Smart contracts are verified by certified experts.
                                                            </p>

                                                            <p className={`${cascading.contentHeader}`}>
                                                                How to Stake Bcoin?
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                It is easy to stake BCoin the liquidity pools by following the instructions in the Staking section.
                                                            </p>

                                                            <p className={`${cascading.contentHeader}`}>
                                                                How to Farm Tokens?
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                Click here to visit the Farming page to get insight on how to Farm your tokens in the BitDealz Exchange.
                                                            </p>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                How to Calculate APR?
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                The annual Percentage Rate (APR) is calculated based on the formula.
                                                            </p>

                                                            <p className={`${cascading.content} mb-4`}>
                                                                <span className={`${cascading.txtHighlights}`}>Total Farm APR </span>= Farm Base APR + LP Rewards APR
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                Note that rewards earned by LP token holders from trading fees are included in the Farm APR calculations.
                                                            </p>

                                                            <p className={`${cascading.contentHeader}`}>
                                                                What is the maximum supply of BCoin?
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                The maximum supply of BCoin is 300000000
                                                            </p>

                                                            <p className={`${cascading.contentHeader}`}>
                                                                Where to get more support?
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                Contact BitDealz Exchange social media platforms to get more information or any other questions, and we are ready to help you out!
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </> : whitepaper === 'step3' ?
                                                <>
                                                    <div className={`${cascading.getstartedContent} ${cascading.bitdealzExchangeContent}`}>
                                                        <h1 className={`${cascading.header} mt-0`}>BitDealz Exchange</h1>
                                                        <div className={`${cascading.group}`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                How to Start with BitDealz Exchange?
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                BitDealz Exchange is the most beginner-friendly platform that enables you to trade your holdings seamlessly.
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                Create and integrate a wallet to the platform (compatible with BNB) and get some BEP-20 Tokens.
                                                            </p>
                                                        </div>

                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                2.1 Step-by-step Instructions To Get Started
                                                            </p>


                                                            <ul className={`${cascading.listContent} mt-3 ms-3`}>
                                                                <li>Go to the BitDealz Exchange page.</li>

                                                                <li>Click Connect Wallet or integrate the wallet in the top-right-hand corner.</li>

                                                                <div className='mt-4 mb-4'>
                                                                    <img src={img11} alt='img1' className={`${cascading.img11} ${cascading.cmnImg} img-fluid`} />
                                                                    <p className={`${cascading.imgFtr} mb-0 mt-2 text-center`}>Connect Wallet Icon</p>
                                                                </div>



                                                                <li>In the upper section, choose the token type to trade (from the list of available tokens) and make sure you have enough tokens in the wallet. You can token balance in the upper section of the same menu.</li>

                                                                <div className='mt-3 mt-sm-4 mt-lg-4 mt-xl-5 mb-4'>
                                                                    <img src={img12} alt='img1' className={`${cascading.img7} ${cascading.cmnImg} img-fluid`} />
                                                                    <p className={`${cascading.imgFtr} mb-0 mt-2 text-center`}>Image Showing Token Selection</p>
                                                                </div>

                                                                <li>Select the token type in the lowest section to start trading.</li>
                                                                <li>Now, enter the amount in the upper section to trade the desired number of tokens you want to swap (spending token), or enter the amount in the lowest section to input the desired number of tokens you want to swap (receiving amount).</li>
                                                                <li>If you enter the amount in one section, the other section&apos;s value will be automatically calculated.</li>
                                                                <li>Now check all the information and click the Swap button.</li>
                                                                <div className='mt-3 mt-sm-4 mt-lg-4 mt-xl-5 mb-4'>
                                                                    <img src={img13} alt='img1' className={`${cascading.img7} ${cascading.cmnImg} img-fluid`}  />
                                                                    <p className={`${cascading.imgFtr} mb-0 mt-2 text-center`}>Image Showing Swap button</p>
                                                                </div>

                                                                <li>First-time trading requires you to click the Enable XXX button for token approval.</li>
                                                                <li>Now, a window appears with extra details. Next, click the Confirm Swap button, and the wallet requests you to confirm the action. </li>
                                                                <li>Now, you can check the completed transaction by clicking the link.</li>

                                                            </ul>




                                                        </div>
                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                2.2 How to add Liquidity?
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                This section helps readers to understand the procedure for adding Liquidity using BCoin and BNB.
                                                            </p>

                                                            <ul className={`${cascading.listContent} mt-3 ms-3`}>
                                                                <li>Go to the Liquidity page.</li>
                                                                <div className='mt-4 mb-4'>
                                                                    <img src={img14} alt='img1' className={`${cascading.img11} ${cascading.cmnImg} img-fluid`} />
                                                                    <p className={`${cascading.imgFtr} mb-0 mt-2 text-center`}>Liquidity page</p>
                                                                </div>
                                                                <li>Click Add Liquidity </li>

                                                                <div className='mt-4 mb-4'>
                                                                    <img src={img15} alt='img1' className={`${cascading.img11} ${cascading.cmnImg} img-fluid`} />
                                                                    <p className={`${cascading.imgFtr} mb-0 mt-2 text-center`}>Add Liquidity Screen</p>
                                                                </div>

                                                            </ul>

                                                            <p className={`${cascading.contentHeader}`}>
                                                                Image Showing Add Liquidity Field
                                                            </p>


                                                            <ul className={`${cascading.listContent} mt-3 ms-3`}>
                                                                <li>On the top left section, choose two tokens (Bcoin and BNB) in the trading pair where you want to provide liquidity.</li>
                                                                <li>Ensure the added liquidity by looking for V2 LP - 0.30 Fee tier.</li>
                                                                <li>Under Deposit Amount, enter the amount for any of the tokens, and the other token value is automatically calculated. </li>
                                                                <li>Note that tokens have enough balance and enter the specific amount or click the Max button to deposit the maximum available value.</li>
                                                                <li>Now, click Enable BCoin.</li>
                                                                <li>Note that while providing liquidity against BNB tokens, click Enable twice for each token in the pair, and now, the wallet requests you to confirm the action. </li>
                                                                <li>Now, click Add to proceed, and again the wallet requests you to confirm the action.</li>
                                                                <li>Next, click Confirm.</li>
                                                                <li>Now, you can check the LP token balance on the Liquidity page.</li>


                                                            </ul>

                                                            <p className={`${cascading.content} mb-4`}>
                                                                In the next section, the guidelines to add liquidity to the pool is given.
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                How to Remove Liquidity?
                                                            </p>

                                                            <ul className={`${cascading.listContent} mt-3 ms-3`}>
                                                                <li>Go to the Liquidity page.</li>
                                                                <li>Under Your Liquidity, select the pair to remove liquidity. </li>
                                                                <li>Use a Filter to check all the liquidity positions.</li>
                                                                <li>Click Remove, and the screen appears.</li>
                                                                <li>On the screen, adjust the liquidity value to be removed using the button or slider or choose Max to remove all liquidity.</li>
                                                                <li>Click Enable, and now, the wallet requests you to confirm the action.</li>
                                                                <li>Now, click Confirm to receive tokens from your pair, and the screen displays the remaining liquidity value on the liquidity page.</li>
                                                            </ul>
                                                        </div>

                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                2.3 How to Use Farms?
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                DeFi Farming allows users to earn rewards (BCoin tokens) for which they should stake two tokens to liquidity.
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                After staking tokens on the liquidity pool, they receive LP tokens. Place LP tokens on Farm for further earning.
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                To get to know the mechanism of DeFi Farming, Visit Farming Section
                                                            </p>
                                                        </div>
                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                2.4 How to Stake in Pools?
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                BitDealz Exchange offers users earn BCoin tokens with its flexible staking options. With flexible staking, users can take BCoin tokens and collect the rewards or unstake the tokens whenever they want.
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                In BitDealz Exchange pools or Liquidity pools, token holders can earn interest by adding liquidity (by staking tokens) to the pools. As a reward, users receive tokens or earn interest based on their staking value.
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                A trader pays a trading fee of 0.2% when they swap tokens on the platform, out of which 0.17% is added to the liquidity pool of the trading pair.
                                                            </p>
                                                        </div>
                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                Flexible Staking
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                Stake the BCoin, and you will get the rewards automatically distributed and reflected in the staking balance. Flexible staking allows you to
                                                            </p>
                                                            <ul className={`${cascading.listContent} mt-3 ms-3`}>
                                                                <li>Add more BCoins to staking.</li>
                                                                <li>Collect rewards anytime </li>
                                                                <li>If you unstake within 72 hours, a 0.1% charge applies.</li>
                                                                <li>After the above specified time, the user can unstake with no fee.</li>
                                                                <li>The fee only applies to manual staking.</li>
                                                                <li>3 day reset time applies whenever you Stake BCoin to the pool</li>
                                                                <li>A performance fee of 0.2% is charged with each yield harvest. For instance, when you harvest 1 BCoin, a performance fee of 0.02 BCoin is charged.</li>

                                                            </ul>
                                                        </div>
                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                2.5 Liquidity Pools
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                When adding tokens to a liquidity pool, you will receive LP tokens. In addition, the liquidity pool helps users to earn fees and BCoin tokens.
                                                            </p>


                                                            <p className={`${cascading.contentHeader}`}>
                                                                LP Tokens
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                When you deposit BCoin and BNB tokens to the liquidity pool, you receive BCoin-BNB LP tokens.
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                LP tokens received reflect your portion of the BCoin-BNB liquidity pool. You can also redeem the funds anytime.
                                                            </p>

                                                            <p className={`${cascading.contentHeader}`}>
                                                                Earn Trading Fees
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                The LP token holders can earn trade fees for their contribution towards the platform&apos;s liquidity. Whenever someone transacts on BitDealz Exchange, they pay a fixed fee of , , of which is added back to the liquidity pool.
                                                            </p>

                                                            <p className={`${cascading.contentHeader}`}>
                                                                Earn BCoin Tokens
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                BitDealz Exchange announces various projects with gamified economics that help users earn BCoin as a reward and keep the community engaged.
                                                            </p>

                                                            <p className={`${cascading.contentHeader}`}>
                                                                Fees
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                To check how much the trading fee is charged for each transaction, check out the Fee section in the swap detail section.
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                To check the type of Liquidity pool and fee tier, check out the Route section
                                                            </p>

                                                        </div>
                                                        <div className={`${cascading.group} mt-4`}>
                                                            <p className={`${cascading.contentHeader}`}>
                                                                2.6 BCoin
                                                            </p>
                                                            <p className={`${cascading.content} mb-4`}>
                                                                Within the BitDealz Exchange ecosystem, the in-built token has various use cases like
                                                            </p>
                                                            <ul className={`${cascading.listContent} mt-3 ms-3`}>
                                                                <li>To provide liquidity</li>
                                                                <li>BCoin can be earned by staking tokens </li>
                                                                <li>As collateral for perpetual trading</li>
                                                                <li>To buy new tokens </li>
                                                                <li>To participate in new projects</li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    <div className={`${cascading.getstartedContent} ${cascading.farming}`}>
                                                        <h1 className={`${cascading.header} mt-0`}>Farming</h1>

                                                        <p className={`${cascading.contentHeader}`}>
                                                            Another exciting way to earn tokens on BitDealz Exchange!
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            In the BitDealz Exchange, farming enables users to earn tokens for free and support the platform by staking LP tokens.
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            <span className={`${cascading.txtHighlights}`}>Note</span> - It is suggested to learn about Farming before starting to Farm
                                                        </p>
                                                        <p className={`${cascading.contentHeader}`}>
                                                            How to Calculate Farm APR?
                                                        </p>

                                                        <p className={`${cascading.content} mb-4`}>
                                                            APR of Farming is calculated based on the following formula.
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            LP Rewards APR - Receive while supporting liquidity.
                                                        </p>

                                                        <p className={`${cascading.content} mb-4`}>
                                                            Farm Base Rewards APR - Received while staking LP tokens in Farm.
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            When you stake LP tokens, you provide liquidity to the pool, and LP rewards are given.
                                                        </p>

                                                        <p className={`${cascading.content} mb-4`}>
                                                            <span className={`${cascading.txtHighlights}`}>Total Farm APR</span> = Farm Base APR + LP Rewards APR
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            Farm Base APR = Total amount of BCoin distributed to the Farm
                                                        </p>

                                                        <p className={`${cascading.content} mb-4`}>
                                                            LP Rewards APR are calculated based on the below steps
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            For instance, let&apos;s consider the following values
                                                        </p>

                                                        <p className={`${cascading.content} mb-4`}>
                                                            <span className={`${cascading.txtHighlights}`}>Liquidity:</span> $387.42M
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            <span className={`${cascading.txtHighlights}`}>Volume 24H:</span> $96.97M
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            <span className={`${cascading.txtHighlights}`}>Volume 7D:</span> 709.73M
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            Fee share of liquidity providers in the pool (based on 24h trading volume) = 24h * 0.17/100
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            Yearly Fees earned by the pool (Projection) = Fee share * 365
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            <span className={`${cascading.txtHighlights}`}>LP Rewards APR %</span> = (Yearly fee/ Total Liquidity) * 100
                                                        </p>
                                                        <p className={`${cascading.contentHeader}`}>
                                                            How Does Farm Work?
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            In the BitDealz Exchange, Farming is the best way to earn BCoin rewards. In Farms, the user should stake two tokens to provide liquidity for which they receive LP tokens. Deposit LP tokens into the Farm to earn rewards like BCoin.
                                                        </p>

                                                        <p className={`${cascading.content} mb-4`}>
                                                            A liquidity position is required to enter the Farm. Note that Farm accepts liquidity positions from its own and exact trading pair with the same fee tier of choice.
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            For instance, BCoin-BNB 0.30% Farm accepts only BCoin-BNB liquidity positions with a 0.30% fee tier selected.
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            To create an exact LP position, provide an exact trading pair with the same fee rate selected. For instance, for BCoin-BNB 0.30% LP Positions, provide liquidity for the BCoin-BNB pair with the 0.30% fee tier.
                                                        </p>
                                                       
                                                        <p className={`${cascading.content} mb-4`}>
                                                            Step-by-step to enter the Farm
                                                        </p>

                                                        <p className={`${cascading.content} mb-4`}>
                                                            1. Locate the right Farm, based on APR and reward rates, and note the trading pair (BCoin-BNB) & fees rate(0.30%).
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            APRs are calculated globally for each position and may vary based on price rate settings
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            2. Provide Liquidity to the Farm, to get LP tokens. On the selected Farm section, click the Get LP (Your Pair) link.
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            3. Add liquidity to the Farm and earn rewards. For this, click the Farms section and locate your Farm. Click on its row and select Enable. 3. Add liquidity to the Farm and earn rewards. For this, click the Farms section and locate your Farm. Click on its row and select Enable.
                                                        </p>
                                                        <div className='mt-4 mb-4'>
                                                            <img src={img21} alt='img1' className={`${cascading.img21} ${cascading.cmnImg} img-fluid`} />
                                                            <p className={`${cascading.imgFtr} mb-0 mt-2 text-center`}>Image Showing Enable Button on Farm Page</p>
                                                        </div>

                                                        <p className={`${cascading.content} mb-4`}>
                                                            4. Then, click the Stake LP button.
                                                        </p>
                                                        <p className={`${cascading.content} mb-4`}>
                                                            5. A pop-up will appear, where the amount of LP tokens to be deposited into the Farm should be entered, or click Max to stake all LP tokens. Next, click Confirm, and the wallet requests you to confirm the action.
                                                        </p>
                                                        <p className={`${cascading.contentHeader}`}>
                                                            How to add Liquidity to the Farm?
                                                        </p>
                                                        <ul className={`${cascading.listContent} mt-3 ms-3`}>
                                                            <li>To add Liquidity to the Farm, go to the Farm page. Click the Staked only toggle. Now, the pairs with LP tokens appear on the screen.</li>
                                                            <div className='mt-4 mb-4'>
                                                                <img src={img22} alt='img1' className={`${cascading.img22} ${cascading.cmnImg} img-fluid`} />
                                                                <p className={`${cascading.imgFtr} mb-0 mt-2 text-center`}>Image Showing Farm Page</p>
                                                            </div>

                                                        </ul>
                                                        <p className={`${cascading.contentHeader}`}>
                                                            Image Showing Farm Page
                                                        </p>
                                                        <ul className={`${cascading.listContent} mt-3 ms-3`}>
                                                            <li>Select the Farm where you&apos;ve staked LP tokens and click its row.</li>
                                                            <li>A pop-up will appear where the amount of LP tokens to be deposited into the Farm should be entered or click Max to add all LP tokens. Next, click Confirm, and the wallet requests you to confirm the action.</li>
                                                            <li>After a short time, a window shows the new LP token&apos;s balance details. Note if you prefer to unstake tokens, any unharvested rewards are collected automatically.</li>
                                                        </ul>
                                                        <p className={`${cascading.contentHeader}`}>
                                                            How to Collect Farm Rewards?
                                                        </p>
                                                        <ul className={`${cascading.listContent} mt-3 ms-3`}>
                                                            <li>Harvest or collect the Farm rewards from the Farm page. To do this, click on the Farm page, visit the chosen Farm, and collect BCoin.</li>
                                                            <li>Next, go to the Farm page, locate the Farm where you’ve staked LP tokens, and click its row. The estimation of total rewards will be shown under the BCoin Earned tab.</li>
                                                            <li>Now, click the Harvest button, and the wallet requests you to confirm the action. Then, the BCoin is sent to the wallet.</li>
                                                            <li>Note there is a small fee for harvesting which is shown when the wallet requests you to confirm the action.</li>
                                                        </ul>
                                                    </div>
                                                </>
                                }







                            </div>
                        </div>
                    </div>
            </div>

            <Footer />
        </>
    )
}
