import React, { useState, useEffect } from 'react';
import cascading from '../assests/css/about.module.css';
import Footer from '../common/footer'
import Header from '../common/header';





import AOS from "aos";
import "aos/dist/aos.css";

export default function Landingpage() {

    useEffect(() => {
        AOS.init({
        });
    }, []);

    return (
        <>
            <Header />
            <div className={`${cascading.aboutbanner}`}>
            <div className={`${cascading.aboutlogo}`}></div>

            </div>



            <div className={`container pb-5 ${cascading.containmax} ${cascading.customContainer} pt-5`}>

                
            <p className={`${cascading.aboutTitle}`}>About Us</p>

            <p className={`${cascading.aboutDesc}`}>BitDealz Exchange stands as a cutting-edge multichain crypto platform designed with our devoted users in mind, providing seamless support for those who engage with the BitDealz service daily. The world of cryptocurrency has undergone significant transformations since the inception of the pioneering cryptocurrency, Bitcoin, in 2009.</p>
            <p className={`${cascading.aboutDesc}`}>In the wake of Bitcoin's introduction, numerous blockchain technologies have emerged, each fostering its unique technological ecosystem. Enthusiastic users have joined these diverse blockchains, propelling us into a new era of financial evolution.</p>
            <p className={`${cascading.aboutDesc}`}>Remaining steadfast in our commitment to customer satisfaction, BitDealz continues to forge ahead, introducing innovative crypto-centric services that cater to the evolving needs of our users. Our primary objective is to furnish users with an optimal experience, allowing them to harness the power of crypto for real-world applications. Yet, our vision extends beyond the present, anticipating the needs of those keen on navigating the ongoing crypto revolution.</p>
            <p className={`${cascading.aboutDesc}`}>What sets us apart from the crowd? BitDealz distinguishes itself through its unwavering emphasis on creativity, passion, long-term vision, and the development of streamlined processes that enhance user convenience. Our brand embarks on a journey to serve our crypto community comprehensively while upholding integrity and adhering to regulations against anti-money laundering and criminal activities, safeguarding the community against potential threats.</p>
            
            <p className={`${cascading.aboutDesc}`}>Empowered by a commitment to building a safer digital world, we leverage cutting-edge cryptocurrency technologies, encapsulated in the essence of the BitDealz brand. We extend a warm invitation to join us on this transformative journey, urging you to become an integral part of the BitDealz community. Together, as a passionate collective, we aspire to contribute significantly to the cryptocurrency world and shape its future.</p>
            <p className={`${cascading.aboutDesc}`}>Discover the extraordinary and transparent projects we've crafted for our users:</p>
            
            <ul className={`${cascading.aboutUl}`}>
                <li><span>Marketplace</span></li>
                <li><span>Games</span></li>
                <li><span>Finance</span> </li>
                <li><span>NFT</span></li>
                <li><span>Explore More...</span></li>
                </ul>
            </div>

            <Footer />
        </>
    )
}
