import React, { useState, useEffect } from 'react';
import cascading from '../assests/css/landing.module.css';
import Footer from '../common/footer'
import Header from '../common/header';
import donutcar from "../assests/images/donutcar.png";
import donutoffice from "../assests/images/donutoffice.png";

import swapdonut from "../assests/images/swap.png";
import liquiditydonut from "../assests/images/liquidity.png";
import poolsdonut from "../assests/images/pools.png";
import boosterdonut from "../assests/images/booster.png";
import cookdonut from "../assests/images/cookdonut.png";

import trade1 from "../assests/images/Group 458.png";
import trade2 from "../assests/images/Group 459.png";
import trade3 from "../assests/images/Group 460.png";

import vector1 from "../assests/images/Vector 1.png";
import vector2 from "../assests/images/Vector 2.png";

import partner1 from "../assests/images/partner1.png";
import partner2 from "../assests/images/partner2.png";
import partner3 from "../assests/images/partner3.png";
import partner4 from "../assests/images/partner4.png";
import partner5 from "../assests/images/partner5.png";
import partner6 from "../assests/images/partner6.png";
import partner7 from "../assests/images/partner7.png";
import partner8 from "../assests/images/partner8.png";
import blurimg from "../assests/images/blurdonut.png";

import cryptocur from "../assests/images/bitdealz/cryptocurrency.png"
import feature from "../assests/images/bitdealz/feature.png"
import feature_1 from "../assests/images/bitdealz/feature_1.png"


import media1 from '../assests/images/media1.png';
import mediahover1 from '../assests/images/mediahover1.png';

import media2 from '../assests/images/media2.png';
import mediahover2 from '../assests/images/mediahover2.png';

import media3 from '../assests/images/media3.png';
import mediahover3 from '../assests/images/mediahover3.png';

import media4 from '../assests/images/media4.png';
import mediahover4 from '../assests/images/mediahover4.png';

import { Link } from 'react-router-dom';

import { CHAINS, FRONT_URL } from '../config/env';

import Marquee from "react-fast-marquee";
import bannerImg from '../assests/images/bitdealz/bannerimg.png'
import Exc from '../assests/images/bitdealz/exc.png'
import Mush from '../assests/images/bitdealz/mush.png'
import Trade from '../assests/images/bitdealz/trade.png'
import Rocket from '../assests/images/bitdealz/rocket.png'
import Arrower from '../assests/images/bitdealz/arrower.png'

import AOS from "aos";
import "aos/dist/aos.css";

export default function Landingpage() {

    const [partner, setPartner] = useState([
        { "id": 1, "image": partner1 }, { "id": 2, "image": partner4, }, { "id": 3, "image": partner5 }, { "id": 4, "image": partner3 }, { "id": 5, "image": partner6, }, { "id": 6, "image": partner7, "soon": "Coming Soon" }, { "id": 7, "image": partner8, "soon": "Coming Soon" }, { "id": 8, "image": partner2, "soon": "Coming Soon" }
    ]);

    useEffect(() => {
        AOS.init({
        });
    }, []);

    return (
        <>
            <Header />


            <div className={`container pb-5 ${cascading.containmax} ${cascading.customContainer}`}>


                <div className={`row pt-0 pt-md-5 pt-lg-0 pb-3 pb-md-5 align-items-center ${cascading.rowmin}`}>
                    <div className={`col-xl-6 col-md-6 mt-5 mt-md-3 ${cascading.posrel}`}>
                        <span className={`text-uppercase  ${cascading.greenSpan}`}>ENJOY THE BENEFITS OF</span>
                        <h1 className={`mb-0 mt-3 ${cascading.welcome} ${cascading.heading1} ${cascading.mainhead}`}>
                            {/* Welcome To <span className={`${cascading.donutback}`}>DonutSwap</span> */}
                            MOST PROMISING AND INSTANT DEX PROTOCOL WITH LOWEST TRADE FEES
                        </h1>
                        <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>Are you wandering for the most user-friendly platform to seamlessly swap your crypto assets? Then, you’ve come to the right place!</p>
                        <p className={`mt-2 mb-0 ${cascading.welcomepara}`}>Trade or swap over 400 crypto tokens without an account.</p>


                        <button className={`rounded-5 mt-3 ${cascading.greenBtn}`}>Get Started</button>

                        <div className={`d-flex justify-content-start align-items-center gap-4 mt-5 ${cascading.popularity}`}>
                            <div className={`${cascading.singlePoplarity}`}>
                                <h6 className={`${cascading.greenPoplarity}`}>100+</h6>
                                <p className={`${cascading.popularityLabel}`}>Wallet Types</p>
                            </div>
                            <div className={`${cascading.singlePoplarity}`}>
                                <h6 className={`${cascading.greenPoplarity}`}>20K+</h6>
                                <p className={`${cascading.popularityLabel}`}>Active Users</p>
                            </div>
                            <div className={`${cascading.singlePoplarity}`}>
                                <h6 className={`${cascading.greenPoplarity}`}>10M+</h6>
                                <p className={`${cascading.popularityLabel}`}>Transactions</p>
                            </div>
                        </div>
                       
                        {/* <Link to="https://donutswap.maticz.in/swap"><button className={`btn mt-4 ${cascading.tradenow}`}>Trade Now</button></Link> */}
                    </div>
                    <div className={` col-xl-6 col-md-6 mt-4 mt-md-3 ${cascading.posrel}`}>
                        <span className={`${cascading.blurviolent}`}></span>
                        {/* <img src={blurimg} alt='img' className={`img-fluid ${cascading.blur1}`} /> */}
                        <img src={bannerImg} alt='img' className={`img-fluid`} />
                    </div>
                </div>

                <div className={`row justify-content-between align-items-center mt-5 ${cascading.revrow}`}>
                    <div className={`col-lg-4 mt-4 mt-lg-0 text-center text-lg-start`}>
                    <img src={Exc} alt='img' className={`img-fluid`} />
                    </div>
                    <div className={`col-lg-7`}>
                        <p className={`${cascading.greenLinerText}`}>Token - All-in-one Token</p>
                        <h1 className={`pe-3 ${cascading.landingInnerTitle}`}>A New-age Crypto Trading Protocol With a Multitude of Benefits</h1>
                        <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>BitDealz Exchange is a ground-breaking protocol that allows users to swap crypto tokens without any account. It runs on a multichain networks with gamified farming mechanics & opens an array of benefits for native token holders like low gas fees, access to reliable DeFi apps, BitDealz Exchange enables users to earn tokens through staking on the same blockchain for the various different tokens which opens up realm of benefits for its holders.</p>
                        <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>BitDealz Exchange constantly introduces new and exciting projects that allow the token holders to earn and win more.</p>
                    </div>
                </div>


                <div className={`text-center pt-1 pt-md-5 pb-4 ${cascading.posrel} ${cascading.posrelbg}`}>
                    
                    <div className={`row justify-content-center mt-4 ${cascading.rowmin}`}>
                        
                        <div className={`col-sm-6 col-md-6 col-xl-4 mb-3`}>
                            <div className={`d-flex justify-content-between align-items-center ${cascading.provideborder}`}>
                            <div className={`d-flex justify-content-start align-items-start flex-column`}>
                            <h5 className={`mt-2 mb-0 ${cascading.welcomess}`}>High Liquidity</h5>
                            <button className={`rounded-5 mt-3 ${cascading.secondaryBtn}`}>Earn</button>
                            </div>
                            <img src={Mush} alt='img' className={`img-fluid ${cascading.donutexp}`} />
                            </div>                      
                        </div>

                        <div className={`col-sm-6 col-md-6 col-xl-4 mb-3`}>
                            <div className={`d-flex justify-content-between align-items-center ${cascading.provideborder}`}>
                            <div className={`d-flex justify-content-start align-items-start flex-column`}>
                            <h5 className={`mt-2 mb-0 ${cascading.welcomess}`}>Farm Earnings</h5>
                            <button className={`rounded-5 mt-3 ${cascading.secondaryBtn}`}>Trade</button>
                            </div>
                            <img src={Trade} alt='img' className={`img-fluid ${cascading.tradeImg}`} />
                            </div>                      
                        </div>

                        <div className={`col-sm-6 col-md-6 col-xl-4 mb-3`}>
                            <div className={`d-flex justify-content-between align-items-center ${cascading.provideborder}`}>
                            <div className={`d-flex justify-content-start align-items-start flex-column`}>
                            <h5 className={`mt-2 mb-0 ${cascading.welcomess}`}>All-new AI Swap</h5>
                            <button className={`rounded-5 mt-3 ${cascading.secondaryBtn}`}>Buy Now</button>
                            </div>
                            <img src={Rocket} alt='img' className={`img-fluid ${cascading.donutexp}`} />
                            </div>                      
                        </div>
                    </div>
                </div>


                <div className={`row pt-5 pb-5 justify-content-between align-items-center ${cascading.rowmin} ${cascading.bluryel}`}>
                    <div className={`col-md-7 col-xl-7 mt-3 ${cascading.posrel}`}>
                        <p className={`${cascading.greenLinerText}`}>Transparent & Trust-worthy</p>
                        <h1 className={`pe-3 ${cascading.landingInnerTitle}`}>Complete Freedom On Your Crypto Assets</h1>
                        <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>BitDealz Exchange enables users to directly trade from the wallet application, just like cash in your money wallet, and gives power to the people with its decentralized ecosystem while allowing them to take ownership of their assets. In addition to the secure and seamless interfaces, high APY and APR are the additional rewards that come along with staking tokens.</p>
                        {/* <Link to={`${FRONT_URL}/swap`}><button className={`btn mt-4 ${cascading.tradenow}`}>Read More</button></Link> */}
                        <button className={`rounded-5 mt-5 ${cascading.greenBtn}`}>Read More</button>
                    </div>
                    <div className={`col-md-5  col-xl-4 mt-3  ${cascading.posrel}`}>
                        <img src={Arrower} alt='img' className={`img-fluid mt-4 mt-md-0`} />
                    </div>
                </div>

                <div className={`${cascading.exchangeBox} mt-5`}>
                    <div className={`card ${cascading.cardcrypto}`}>
                    <div className='card-body py-4'>
                    <div className="row align-items-center">
    <div className="col-12 col-md-4 col-lg-3 mt-4 mt-md-0 text-center">
    <img src={cryptocur} className={`img-fluid`} alt="How it works" />
    </div>
    <div className="col-12 col-md-6 col-lg-5 offset-md-2 offset-lg-4 text-center text-md-start">
        <p className={`${cascading.whitetextlarge}`}>Start Exchange On Crypto currency</p>
        <Link to="/swap" className={`rounded-5 mt-2 ${cascading.greenBtn} d-inline-block`}>Start Now</Link>
   
</div>
</div>
</div>
</div>
                </div>


                <div className={`row justify-content-between align-items-center mt-5 pt-4 ${cascading.bannersecsixth} ${cascading.revrow}`}>
                    <div className={`col-lg-4 ${cascading.imgsecafter} mt-4 mt-lg-0 text-center text-lg-start`}>
                    <img src={feature} alt='img' className={`img-fluid`} />
                    </div>
                    <div className={`col-lg-7`}>
                        <p className={`${cascading.greenLinerText}`}>Join The Next-gen Swap Protocol</p>
                        <h1 className={`pe-3 ${cascading.landingInnerTitle}`}>How Does BitDealz Exchange Re-defines The Swap Platform?</h1>
                        
                        <ul className={`${cascading.bannerUlhome} mt-4`}>
                            <li>
                                <span>Secure, faster, and beginner-friendly platform with stable yields</span>
                                </li>
                                <li>
                                    <span>Supported multichain networks</span>
                                    </li>
                                    <li>
                                        <span>Revenue-sharing protocol with very low fees</span>
                                        </li>
                                        <li>
                                            <span>Highly Secured multichain with ground-breaking benefits </span>
                                            </li>
                                            <li>
                                                <span>Earn Token through Farming or Staking</span>
                                                </li>
                                                <li>
                                                    <span>High Liquidity and constant innovations</span>
                                                    </li>
                                                    </ul>

        <Link to="/swap" className={`rounded-5 mt-4 ${cascading.secondaryBtn} d-inline-block`}>AI Swap</Link>
                        
                    </div>
                </div>


                <div className={`row pt-5 pb-5 justify-content-between align-items-center ${cascading.rowmin}`}>
                    <div className={`col-md-7 col-xl-7 mt-3 ${cascading.posrel}`}>
                        <h1 className={`pe-3 ${cascading.landingInnerTitle}`}>Join the Next Big-thing In The DeFi Space</h1>
                        <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>We are continuously innovating and launching multiple Perpetual Contract markets. So, Join us now!</p>
                    </div>
                    <div className={`col-md-5  col-xl-4 mt-3  ${cascading.posrel} ${cascading.imgsecaftsev}`}>
                        <img src={feature_1} alt='img' className={`img-fluid mt-4 mt-md-0`} />
                    </div>
                </div>


                <div className={`row pt-5 pb-0  justify-content-between align-items-center ${cascading.rowmin}`}>
                    <div className={`col-md-12 col-xl-12 mt-3 ${cascading.posrel}`}>
                    <p className={`${cascading.greenLinerText} ${cascading.greenLinerTextsm}`}>Milestones</p>

                       
                        <h1 className={`pe-3 ${cascading.landingInnerTitle}`}>Mind-blowing Achievements Showing That BitDealz Exchange Is the Future</h1>
                    
                    <div className='row mt-md-5 mt-4'>
                        <div className='col-12 col-md-6 col-lg-4 mb-md-5 mb-3'>
                            <div className={`${cascading.cardborder} card`}>
                                <div className='card-body p-4'>
                                    <p className={`${cascading.cardBortitle}`}>Total Investments</p>
                                    <p className={`${cascading.cardBordesc} mb-0`}>$350,000,000</p>
                                </div>

                            </div>
                        </div>

                        <div className='col-12 col-md-6 col-lg-4 mb-md-5 mb-3'>
                            <div className={`${cascading.cardborder} card`}>
                                <div className='card-body p-4'>
                                    <p className={`${cascading.cardBortitle}`}>Pools Locked</p>
                                    <p className={`${cascading.cardBordesc} mb-0`}>$150,000,000</p>
                                </div>

                            </div>
                        </div>

                        <div className='col-12 col-md-6 col-lg-4 mb-md-5 mb-3'>
                            <div className={`${cascading.cardborder} card`}>
                                <div className='card-body p-4'>
                                    <p className={`${cascading.cardBortitle}`}>Total Airdrop</p>
                                    <p className={`${cascading.cardBordesc} mb-0`}>$25,000,000</p>
                                </div>

                            </div>
                        </div>

                        <div className='col-12 col-md-6 col-lg-4 mb-md-5 mb-3'>
                            <div className={`${cascading.cardborder} card`}>
                                <div className='card-body p-4'>
                                    <p className={`${cascading.cardBortitle}`}>Community</p>
                                    <p className={`${cascading.cardBordesc} mb-0`}>5M+</p>
                                </div>

                            </div>
                        </div>

                        <div className='col-12 col-md-6 col-lg-4 mb-md-5 mb-3'>
                            <div className={`${cascading.cardborder} card`}>
                                <div className='card-body p-4'>
                                    <p className={`${cascading.cardBortitle}`}>Web3 Projects</p>
                                    <p className={`${cascading.cardBordesc} mb-0`}>15+</p>
                                </div>

                            </div>
                        </div>

                        <div className='col-12 col-md-6 col-lg-4 mb-md-5 mb-3'>
                            <div className={`${cascading.cardborder} card`}>
                                <div className='card-body p-4'>
                                    <p className={`${cascading.cardBortitle}`}>BlockChains Supported</p>
                                    <p className={`${cascading.cardBordesc} mb-0`}>50+</p>
                                </div>

                            </div>
                        </div>

                    </div>
                    </div>
                   
                </div>

                
             

            

            </div>

            <Footer />
        </>
    )
}
